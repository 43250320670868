/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect, useState } from "react";
import cx from "classnames";
import { IonCardContent, IonIcon, IonInput, IonNote, IonText } from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import { useForm, SubmitHandler, Controller, useController } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { CountryCode } from "libphonenumber-js/core";
import { getLocationError, isValidLocation } from "utils/service/google-maps-service";
import { SelectItem } from "types/forms";
import { Product } from "types/admissions";

import { NxuAlert, NxuComponentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import NxuPhoneInput from "components/atom/nxu-phone-input";
import ContentfulModal from "components/molecule/contentful-modal";
import NxuSelect from "components/atom/nxu-select/nxu-select";
import NxuLocationField from "components/atom/nxu-location-field";
import { LocationSelectItem } from "components/atom/nxu-location-field/nxu-location-field";

import { LearnerProfileData, FetchLearnerProfileResponse, SubmitLearnerProfileResponse } from "types/learner-profile";
import { usePrivacyPolicy, useTermsConditions } from "utils/hooks/agreement";
import { useSubmitPersonalInfo } from "utils/hooks/learner-profile";
import { HttpError } from "utils/errors/HttpError";

import "./application-form.scss";
import { PartnerDetails } from "types/partners";
import { useRegistrationContext } from "utils/context/registration";
import { RequirementType } from "types/registrations";

const HOW_DID_YOU_HEAR_OPTIONS: SelectItem[] = [
  { label: "Facebook", value: "facebook" },
  { label: "Instagram", value: "instagram" },
  { label: "Twitter", value: "twitter" },
  { label: "Google Search", value: "googleSearch" },
  { label: "Nexford Affiliate", value: "nexfordAffiliate" },
  { label: "Friend / family", value: "friend" },
  { label: "Other", value: "other" },
];

const GENDER_OPTIONS: SelectItem[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
  { label: "Prefer not to say", value: "NotProvided" },
];

const PERSONAL_INFO_SUBMISSION_ERROR: { [key: string]: any } = {
  BlockedState:
    "We are unable to accept learners who reside in the following states: Alabama, Alaska, Arkansas, Delaware, Georgia, Illinois, Indiana, Iowa, Kansas, Maryland, Michigan, Minnesota, Montana, New Mexico, New York, North Dakota, Oregon, Utah, Wisconsin, and Wyoming.",
  BlockedCountry: "We are unable to accept learners who reside in your country",
};

type SchemaOptions = {
  isTermsRequired: boolean;
};
// Application Form validation schema
const getApplicationFormSchema = ({ isTermsRequired }: SchemaOptions) =>
  yup.object().shape({
    FirstName: yup.string().required("First Name is a required field").max(40),
    LastName: yup.string().required("Last Name is a required field").max(80),
    YearOfBirth: yup.number().required("Year of Birth is a required field"),
    FullPhoneNumber: yup.string().required("Phone Number is a required field"),
    Nationality: yup.string().required("Nationality is a required field"),
    Location: yup.string().when("Country", {
      is: (val: any) => !val,
      then: (schema) => schema.required("City and Country are required"),
    }),
    Country: yup.string(),
    State: yup.string(),
    City: yup.string(),
    EnglishNativeSpeaker: yup.string().when("ProductType", {
      is: (val: any) => val === "Degree" || val === "Certificate",
      then: (schema) => schema.required("Your English Proficiency level is required"),
    }),
    EnglishEducationLanguage: yup.string().when("EnglishNativeSpeaker", {
      is: (val: any) => val === "no",
      then: (schema) => schema.required("Your English Proficiency level is required"),
    }),
    Gender: yup.string().required(),
    HowDidYouHear: yup.string().required("How did you hear about us is a required field"),
    // Values set from props
    Email: yup.string().required(),
    ProductCode: yup.string().required(),
    ProductType: yup.string().required(),
    AcceptTerms: yup.bool().when((_, schema) => {
      if (isTermsRequired) {
        return schema.oneOf([true], "This enrollment cannot proceed without the Terms & Conditions being accepted.");
      }

      return schema.notRequired();
    }),
  });

export interface ApplicationFormProps {
  yearsList?: Array<SelectItem>;
  nationalitiesList?: Array<SelectItem>;
  programDetails?: Product;
  existingApplicantInfo?: FetchLearnerProfileResponse;
  partnerDetails?: PartnerDetails;
  email: string;
  onSuccess: (resp: SubmitLearnerProfileResponse) => void;
}

/**
 * Single step application form
 */
const ApplicationForm = (props: ApplicationFormProps) => {
  const { registrationData } = useRegistrationContext();
  const { yearsList, nationalitiesList, programDetails, existingApplicantInfo, email, onSuccess, partnerDetails } =
    props;

  const DYNAMIC_PERSONAL_INFO_SUBMISSION_ERROR: { [key: string]: any } = {
    BlockedState:
      "We are unable to accept learners who reside in the following states: Alabama, Alaska, Arkansas, Delaware, Georgia, Illinois, Indiana, Iowa, Kansas, Maryland, Michigan, Minnesota, Montana, New Mexico, New York, North Dakota, Oregon, Utah, Wisconsin, and Wyoming.",
    BlockedCountry: "We are unable to accept learners who reside in your country",
    InvalidCountryForPartner: `The ${programDetails?.FriendlyName} program is currently unavailable in the selected country of residence under the partnership with ${partnerDetails?.name}. For further assistance or alternative options, please contact support.`,
  };

  const [shouldAskEnglishLevel, setShouldAskEnglishLevel] = useState(false);
  const [isEnglishEducationVisible, setIsEnglishEducationVisible] = useState(false);
  const [showEnglishAssessmentMsg, setShowEnglishAssessmentMsg] = useState(false);

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

  const [submissionError, setSubmissionError] = useState<string>();
  const { mutate, isPending: submitIsPending, isSuccess } = useSubmitPersonalInfo(onSuccess);

  const {
    data: privacyPolicy,
    isLoading: privacyPolicyLoading,
    isError: privacyPolicyError,
  } = usePrivacyPolicy(privacyPolicyOpen);

  const {
    data: termsAndConditions,
    isLoading: termsAndConditionsLoading,
    isError: termsAndConditionsError,
  } = useTermsConditions(programDetails?.ProductProvider || "", !!programDetails?.ProductProvider);

  const isTermsAndConditionsRequired =
    registrationData?.requirements.some(
      (requirement) => requirement.requirement === RequirementType.TermsAndConditions,
    ) ?? false;

  const newApplicationForm = useForm({
    defaultValues: {
      Email: email || "",
      FirstName: existingApplicantInfo?.FirstName || "",
      LastName: existingApplicantInfo?.LastName || "",
      YearOfBirth: existingApplicantInfo?.BirthYear,
      ProductCode: programDetails?.ProductCode || "",
      ProductType: programDetails?.ProductType || "",
      FullPhoneNumber: existingApplicantInfo?.PhoneNumber
        ? `+${existingApplicantInfo.PhoneCountryCode} ${existingApplicantInfo.PhoneNumber}`
        : "",
      Nationality: existingApplicantInfo?.Nationality || "",
      Location:
        existingApplicantInfo?.City && existingApplicantInfo?.Country
          ? `${existingApplicantInfo.City}, ${existingApplicantInfo.Country}`
          : "",
      Country: existingApplicantInfo?.Country || "",
      State: existingApplicantInfo?.State || "",
      City: existingApplicantInfo?.City || "",
      EnglishNativeSpeaker: "",
      EnglishEducationLanguage: "",
      Gender: existingApplicantInfo?.Gender || "",
      HowDidYouHear: existingApplicantInfo?.HowFoundNexford || "",
      AcceptTerms: false,
    },
    resolver: yupResolver(
      getApplicationFormSchema({
        isTermsRequired: isTermsAndConditionsRequired,
      }),
    ),
  });
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { isValid, errors },
  } = newApplicationForm;

  const handleApplicationSubmit: SubmitHandler<any> = async (formValues) => {
    if (!isValidLocation(formValues.Country, formValues.State, formValues.City)) {
      setError("Location", {
        type: "manual",
        message: getLocationError(formValues.Country, formValues.State, formValues.City),
      });
      return;
    }

    if (!isValidPhoneNumber(formValues.FullPhoneNumber)) {
      setError("FullPhoneNumber", {
        type: "manual",
        message: "This phone number is invalid",
      });
      return;
    }

    const phoneNumber = parsePhoneNumber(formValues.FullPhoneNumber);
    if (!isValid || !phoneNumber) return;
    setSubmissionError(undefined);

    const payload: LearnerProfileData = {
      FirstName: formValues.FirstName,
      LastName: formValues.LastName,
      BirthYear: formValues.YearOfBirth,
      Country: formValues.Country,
      State: formValues.State,
      Nationality: formValues.Nationality,
      Email: formValues.Email,
      Gender: formValues.Gender,
      HowFoundNexford: formValues.HowDidYouHear,
      PhoneCountryCode: phoneNumber.countryCallingCode,
      PhoneNumber: phoneNumber.nationalNumber,
      TermsAndConditionsVersion: termsAndConditions!.Version,
      TermsAndConditionsProvider: programDetails!.ProductProvider,
      ProductType: programDetails!.ProductType,
      ProductCode: programDetails!.ProductCode,
      City: formValues.City,
      IsNativeEnglishSpeaker: null,
      IsEnglishPrimaryEducationLanguage: null,
      PartnerId: partnerDetails?.partnerId,
    };

    if (shouldAskEnglishLevel) {
      payload.IsNativeEnglishSpeaker = formValues.EnglishNativeSpeaker === "yes";
      payload.IsEnglishPrimaryEducationLanguage =
        formValues.EnglishNativeSpeaker === "no" && formValues.EnglishEducationLanguage === "yes";
    }

    mutate(payload, {
      onSuccess() {},
      onError(e) {
        const error = e as HttpError<any>;
        setSubmissionError(
          PERSONAL_INFO_SUBMISSION_ERROR[error.data.errorCode] ||
            DYNAMIC_PERSONAL_INFO_SUBMISSION_ERROR[error.data.errorCode] ||
            error.message,
        );
      },
    });
  };

  useEffect(() => {
    setShouldAskEnglishLevel(
      !!programDetails && (programDetails.ProductType === "Degree" || programDetails.ProductType === "Certificate"),
    );
  }, [programDetails]);

  const {
    field: { value: yearOfBirthValue, onChange: yearOfBirthOnChange },
  } = useController({ name: "YearOfBirth", control });
  const {
    field: { value: locationValue, onChange: locationOnChange },
  } = useController({ name: "Location", control });
  const {
    field: { value: NationalityValue, onChange: NationalityOnChange },
  } = useController({ name: "Nationality", control });
  const {
    field: { value: GenderValue, onChange: GenderOnChange },
  } = useController({ name: "Gender", control });
  const {
    field: { value: HowDidYouHearValue, onChange: HowDidYouHearOnChange },
  } = useController({ name: "HowDidYouHear", control });

  const locationToPayload = (selectedLocation?: LocationSelectItem, locationDetails?: google.maps.GeocoderResult[]) => {
    if (selectedLocation && locationDetails?.length) {
      locationOnChange(selectedLocation.value);

      const { address_components } = locationDetails[0];

      const countryComp = address_components.find((comp) => comp.types.includes("country"));
      setValue("Country", countryComp?.short_name || "");

      if (countryComp?.short_name === "US") {
        const stateComp = address_components.find((comp) => comp.types.includes("administrative_area_level_1"));
        setValue("State", stateComp?.short_name || "");
      } else {
        setValue("State", "");
      }

      let cityComp = address_components.find((comp) => comp.types.includes("postal_town"));
      if (!cityComp) cityComp = address_components.find((comp) => comp.types.includes("locality"));
      if (!cityComp) cityComp = address_components.find((comp) => comp.types.includes("sublocality"));
      if (!cityComp) cityComp = address_components.find((comp) => comp.types.includes("administrative_area_level_3"));
      if (!cityComp) cityComp = address_components.find((comp) => comp.types.includes("administrative_area_level_2"));
      setValue("City", cityComp?.long_name || "");
    } else {
      setValue("Location", "");
      setValue("Country", "");
      setValue("State", "");
      setValue("City", "");
    }
  };

  if (termsAndConditionsLoading) {
    return <NxuComponentLoading />;
  }

  if (termsAndConditionsError) {
    return (
      <NxuAlert
        message={
          "There was a problem loading our terms and conditions, please refresh the page. If the problem persists please contact support"
        }
      />
    );
  }

  return (
    <CardPanel>
      <form
        className="application-form"
        onSubmit={handleSubmit(handleApplicationSubmit)}
        data-testid="application-form"
      >
        <Controller
          control={control}
          name="FirstName"
          render={({ field, fieldState }) => (
            <IonInput
              fill="outline"
              disabled={submitIsPending || isSuccess}
              onInput={field.onChange}
              onIonBlur={field.onBlur}
              type="text"
              placeholder="First Name"
              aria-label="First Name"
              errorText={fieldState.error?.message}
              className={fieldState.error ? "ion-touched ion-invalid" : ""}
              value={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="LastName"
          render={({ field, fieldState }) => (
            <IonInput
              fill="outline"
              disabled={submitIsPending || isSuccess}
              onIonInput={field.onChange}
              onIonBlur={field.onBlur}
              type="text"
              placeholder="Last Name"
              aria-label="Last Name"
              errorText={fieldState.error?.message}
              className={fieldState.error ? "ion-touched ion-invalid" : ""}
              value={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="YearOfBirth"
          render={({ field, fieldState }) => (
            <NxuSelect
              selectedValue={yearOfBirthValue}
              onChange={(option) => yearOfBirthOnChange(option.value)}
              // @ts-ignore
              options={yearsList}
              defaultOptionIndex={9}
              classNamePrefix="nxu-select__location"
              field={field}
              fieldState={fieldState}
              placeholder={"Year of Birth"}
              isDisabled={submitIsPending || isSuccess}
            />
          )}
        />

        <Controller
          control={control}
          name="Location"
          render={({ fieldState }) => (
            <NxuLocationField
              fieldName="Location"
              onAutocompleteLoadFailed={() =>
                setError("Location", {
                  type: "manual",
                  message: "The location lookup returned no results",
                })
              }
              onGeocodeLookupFailed={() =>
                setError("Location", {
                  type: "manual",
                  message: "There was an error on getting your selected location, please try again",
                })
              }
              defaultValue={locationValue || ""}
              onChange={(option, details) => locationToPayload(option, details)}
              fieldState={fieldState}
              placeholder={"Town or city you live in"}
              isDisabled={submitIsPending || isSuccess}
            />
          )}
        />

        <NxuPhoneInput
          name="FullPhoneNumber"
          control={control}
          rules={{
            validate: (value: any) => isValidPhoneNumber(value),
          }}
          fieldId="FullPhoneNumber"
          defaultCountry={(getValues("Country") as CountryCode) || "US"}
          isDisabled={submitIsPending || isSuccess}
          errors={errors.FullPhoneNumber}
        />

        <Controller
          control={control}
          name="Nationality"
          render={({ field, fieldState }) => (
            <NxuSelect
              selectedValue={NationalityValue}
              onChange={(option) => NationalityOnChange(option.value)}
              // @ts-ignore
              options={nationalitiesList}
              field={field}
              fieldState={fieldState}
              placeholder={"Nationality"}
              isDisabled={submitIsPending || isSuccess}
            />
          )}
        />

        <Controller
          control={control}
          name="Gender"
          render={({ field, fieldState }) => (
            <NxuSelect
              selectedValue={GenderValue}
              onChange={(option) => GenderOnChange(option.value)}
              options={GENDER_OPTIONS}
              field={field}
              fieldState={fieldState}
              placeholder={"Gender"}
              isDisabled={submitIsPending || isSuccess}
            />
          )}
        />

        {shouldAskEnglishLevel && (
          <>
            <fieldset
              className={cx(
                "application-form__radio-group",
                errors.EnglishNativeSpeaker && "application-form__radio-group--error",
              )}
            >
              <p>Are you a native English speaker?</p>
              <label htmlFor="native-speaker-true">
                <input
                  {...register("EnglishNativeSpeaker")}
                  onChange={() => setIsEnglishEducationVisible(false)}
                  type="radio"
                  value="yes"
                  id="native-speaker-true"
                />
                Yes
              </label>
              <label htmlFor="native-speaker-false">
                <input
                  {...register("EnglishNativeSpeaker")}
                  onChange={() => setIsEnglishEducationVisible(true)}
                  type="radio"
                  value="no"
                  id="native-speaker-false"
                />
                No
              </label>
              {errors.EnglishNativeSpeaker && (
                <IonNote className="application-form__error-note">{errors.EnglishNativeSpeaker?.message}</IonNote>
              )}
            </fieldset>

            {isEnglishEducationVisible && (
              <fieldset
                className={cx(
                  "application-form__radio-group",
                  errors.EnglishEducationLanguage && "application-form__radio-group--error",
                )}
              >
                <p>Have you earned a degree from an accredited university taught in English?</p>
                <label htmlFor="education-language-true">
                  <input
                    {...register("EnglishEducationLanguage")}
                    onChange={() => setShowEnglishAssessmentMsg(false)}
                    type="radio"
                    value="yes"
                    id="education-language-true"
                  />
                  Yes
                </label>
                <label htmlFor="education-language-false">
                  <input
                    {...register("EnglishEducationLanguage")}
                    onChange={() => setShowEnglishAssessmentMsg(true)}
                    type="radio"
                    value="no"
                    id="education-language-false"
                  />
                  No
                </label>
                {errors.EnglishEducationLanguage && (
                  <IonNote className="application-form__error-note">{errors.EnglishEducationLanguage?.message}</IonNote>
                )}
              </fieldset>
            )}

            {isEnglishEducationVisible && showEnglishAssessmentMsg && (
              <div className="application-form__anglish-assement-note">
                <IonCardContent>
                  <IonIcon icon={warningOutline} />
                  <IonText>
                    Please be aware that all Nexford University programs are taught in English. Prior to starting your
                    program, you will be required to provide acceptable English language proficiency results from an
                    approved assessment
                  </IonText>
                </IonCardContent>
              </div>
            )}
          </>
        )}

        <Controller
          control={control}
          name="HowDidYouHear"
          render={({ field, fieldState }) => (
            <NxuSelect
              selectedValue={HowDidYouHearValue}
              onChange={(option) => HowDidYouHearOnChange(option.value)}
              options={HOW_DID_YOU_HEAR_OPTIONS}
              field={field}
              fieldState={fieldState}
              placeholder={"How did you hear about us"}
              isDisabled={submitIsPending || isSuccess}
            />
          )}
        />

        {isTermsAndConditionsRequired && (
          <div className={cx("application-form__checkbox", errors.AcceptTerms && "application-form__checkbox--error")}>
            <div className="form-group form-check">
              <input type="checkbox" {...register("AcceptTerms")} disabled={submitIsPending || isSuccess} />
              <label htmlFor="AcceptTerms" className="form-check-label">
                I have read and understand the{" "}
                <button type="button" onClick={() => setTermsAndConditionsOpen(true)}>
                  terms & conditions
                </button>{" "}
                and{" "}
                <button type="button" onClick={() => setPrivacyPolicyOpen(true)}>
                  privacy policy
                </button>
              </label>
            </div>
            {errors.AcceptTerms && (
              <IonNote className="application-form__error-note">{errors.AcceptTerms?.message}</IonNote>
            )}
          </div>
        )}

        {!!submissionError && (
          <div className="application-form__submission-error">
            <NxuAlert fullWidth={true} message={submissionError} />
          </div>
        )}

        <NxuPrimaryButton type="submit" expand="block" disabled={submitIsPending || isSuccess}>
          {submitIsPending ? "Registration in progress" : "Register"}
        </NxuPrimaryButton>
      </form>

      <ContentfulModal
        modalTitle="Privacy Policy"
        isError={privacyPolicyError}
        isLoading={privacyPolicyLoading}
        content={privacyPolicy?.Content || ""}
        isOpen={privacyPolicyOpen}
        closeModal={() => setPrivacyPolicyOpen(false)}
      />
      <ContentfulModal
        modalTitle="Terms & Conditions"
        isError={termsAndConditionsError}
        isLoading={termsAndConditionsLoading}
        content={termsAndConditions?.Content || ""}
        isOpen={termsAndConditionsOpen}
        closeModal={() => setTermsAndConditionsOpen(false)}
      />
    </CardPanel>
  );
};

export default ApplicationForm;
